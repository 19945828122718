
















import { Component, Vue, Prop } from 'vue-property-decorator'
import axiosInstance from '@/plugins/axios'
import axios from 'axios'

import vueFilePond from 'vue-filepond'
import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size'

const FilePond = vueFilePond(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize
)

@Component({
  components: {
    FilePond
  }
})
export default class ProductPictureUpload extends Vue {
  @Prop({ default: 1 }) readonly gridCol!: number
  @Prop({ default: () => [] }) readonly initialPictures!: string[]

  pictures: string[] = []
  unwatchInitialPicturesHandler = undefined

  server = {
    timeout: 30000,
    process: async (fieldName, file, metadata, load, error, progress, abort) => {
      try {
        const getUrlRes = await axiosInstance.get('/manager/getuploadurl')
        const { filename, url } = getUrlRes.data

        const cancelToken = axios.CancelToken
        const source = cancelToken.source()

        axiosInstance.put(url, file, {
          cancelToken: source.token,
          baseURL: '',
          headers: {
            'Content-Type': file.type,
            'Cache-Control': 'public, max-age=31536000'
          },
          transformRequest: [(data, headers) => {
            delete headers!.Authorization
            return data
          }],
          onUploadProgress: (e) => progress(e.lengthComputable, e.loaded, e.total)
        })
          .then(() => {
            console.log('PICTURE_URL', `https://sokxayallplus.s3.ap-southeast-1.amazonaws.com/products/${filename}`)
            this.pictures.push(filename)
            this.$emit('picturesChanged', this.pictures)
            load(filename)
          })
          .catch(uploadErr => {
            console.error('UPLOAD_ERROR', uploadErr)
            error(uploadErr.message)
          })

        return {
          abort: () => {
            source.cancel('User abort upload')
            abort()
          }
        }
      } catch (error) {
        console.error(error)
        error(error.message)
      }
    },
    revert: (filename: string, load): void => {
      console.log('revert')
      const index = this.pictures.findIndex(picture => picture === filename)
      if (index !== -1) {
        this.pictures.splice(index, 1)
        this.$emit('picturesChanged', this.pictures)
      }
      load()
    },
    load: {
      url: 'https://cdn.statically.io/img/sokxayallplus.s3.ap-southeast-1.amazonaws.com/w=400,f=auto,q=100/products/'
    },
    restore: null
  }

  mounted () {
    const sources = []
    this.initialPictures.forEach(picture => {
      this.pictures.push(picture)
      sources.push({
        // @ts-ignore
        source: picture,
        options: {
          // @ts-ignore
          type: 'local'
        }
      })
    })

    if (sources.length > 0) {
      // @ts-ignore
      this.$refs.filepond.addFiles(sources)
    }
  }

  onReorderFiles (files: any[]): void {
    // @ts-ignore
    this.pictures = files.map(file => file.serverId)
    this.$emit('picturesChanged', this.pictures)
  }

  onRemoveFile (error, file: any): void {
    if (error || file.serverId === null) return
    const index = this.pictures.findIndex(picture => picture === file.serverId)
    if (index !== -1) {
      this.pictures.splice(index, 1)
      this.$emit('picturesChanged', this.pictures)
    }
  }
}
