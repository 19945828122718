import { render, staticRenderFns } from "./ProductPictureUpload.vue?vue&type=template&id=4af4db34&scoped=true&"
import script from "./ProductPictureUpload.vue?vue&type=script&lang=ts&"
export * from "./ProductPictureUpload.vue?vue&type=script&lang=ts&"
import style0 from "./ProductPictureUpload.vue?vue&type=style&index=0&id=4af4db34&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4af4db34",
  null
  
)

export default component.exports